<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
      <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" v-can:hide.distributor />
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" v-can:hide.distributor />
        <filter-area class="mt-2" v-model:value="area" v-model:provinsi="provinsi" v-model:region="region" v-can:hide.distributor />
      </div>
    </div>
     <div class="row">
      <div class="col-md-12">
           <filter-kabupaten-kota class="mr-2 mt-2" v-model:value="kabupaten" v-model:kabupaten="kabupaten" v-model:area="area" v-can:hide.distributor />
      <filter-distributor class="mr-2 mt-2"  v-model:value="distributor"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
          v-can:hide.distributor></filter-distributor>
        <filter-brands class="mr-2 mt-2" v-model:value="brand" v-model:brand="brand" />
      </div>
    </div>
     <div class="row">
      <div class="col-md-12">
            <filter-products class="mr-2 mt-2" v-model:value="product" v-model:brand="brand" />
      <a-select
          :options="[
            { value: null, label: 'Pilih UOM' },
            { value: 1, label: 'TON' },
            { value: 2, label: 'ZAK' },
          ]"
         class="mr-2 mt-2"
          style="width: 300px;"
          placeholder="Semua UOM"
          v-model:value="uom"
        ></a-select>
          <filter-segment
          class="mr-2 mt-2"
          :mode="null"
          style="width: 300px;"
          placeholder="Pilih Tipe Customer"
          v-model:value="segment"
        />
      </div>
    </div>
     <div class="row">
      <div class="col-md-12">
        <a-month-picker
              show
              class="mr-2 mt-2"
              v-model:value="start_date"
              placeholder="Dari Bulan"
              :disabled-date="disabledStartDate"
              style="width: 300px;"
               format="MMMM YYYY"

            />
            <a-month-picker
            class="mr-2 mt-2"
              v-model:value="end_date"
              placeholder="Sampai Bulan"
              style="width: 300px;"
              :disabled-date="disabledEndDate"
                 format="MMMM YYYY"
            />
         <a-input-search
         class="mr-2 mt-2"
                v-model:value="q"
                placeholder="Cari ..."
                style="width: 300px"
                @search="onSearch"
              />
      <a-button class="mr-2 mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
     <div class="row justify-content-end">
         <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <a-button class="mr-3 mb-2"  title="Download Excel" type="primary"  @click="fetchXlsx" :loading="isDownloading">
              <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
              <span v-else>Downloading ...</span>
            </a-button>
          </div>
       </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >

      <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #nilai="{ text }">
          <span>{{ text ? new Intl.NumberFormat("id-ID").format(text) : 0 }}</span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted, nextTick, toRefs, onBeforeMount, provide, watch } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterSegment from '@/components/filter/FilterSegment'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
   {
    title: 'No',
    slots: { customRender: 'no' },
  },
   {
    title: 'Bulan',
    dataIndex: 'bulan',
  },
   {
    title: 'Tahun',
    dataIndex: 'tahun',
  },
  {
    title: 'ID BK',
    dataIndex: 'id_bk',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'toko_name',
  },
  {
    title: 'Provinsi Gudang',
    dataIndex: 'wr_prov',
  },
   {
    title: 'Kabupaten / Kota Gudang',
    dataIndex: 'wr_kab',
  },
  {
    title: 'Region Gudang',
    dataIndex: 'wr_reg',
  },
  {
    title: 'Area Gudang',
    dataIndex: 'wr_area',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'code_distri',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distri_name',
  },
   {
    title: 'Tipe Customer',
    dataIndex: 'type_name',
  },
  {
    title: 'Produk',
    dataIndex: 'product_name',
  },
  {
    title: 'UoM Produk',
    dataIndex: 'uom',
  },
  {
    title: 'Total Volume',
    dataIndex: 'total_volume',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Total Transaksi',
    dataIndex: 'total_trx',
  },
  {
    title: 'Cluster',
    dataIndex: 'cluster_name',
  },
   {
    title: 'SSM',
    dataIndex: 'user_ssm',
    // title_xls: 'SSM',
  },
  {
    title: 'ASM',
    dataIndex: 'user_sm',
    // title_xls: 'ASM',
  },
  {
    title: 'TSO',
    dataIndex: 'user_am',
    // title_xls: 'TSO',
  },

]
export default defineComponent({
  name: 'MFilter',
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributor,
    FilterBrands,
    FilterProducts,
    FilterKabupatenKota,
    FilterSegment,
  },
  setup() {
    const columns = ref([])
    const data = ref([])
    const { isDistributor, vendor_id } = useUserInfo()

    columns.value = acolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const product = ref([])
    const distributor = ref([])
    const area = ref([])
    const brand = ref([])
    const segment = ref([])

    const dataProduct = ref([])
    const dataBrand = ref([])
    const uom = ref(null)
     const errorMessage = ref(null)

     const {
      start_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()
    start_date.value = moment(new Date())
    end_date.value = moment(new Date())
    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
    }

    const params = ref({})
    const fetchData = () => {
    const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
       product: product.value,
        brand: brand.value,
        distributor: distributor.value,
        start_date: firstDayMonth.value,
        end_date: lastDayMonth.value,
        type: segment.value,
        q: q.value,
        uom: uom.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report/transaksi-toko', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
  const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/transaksi-toko', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `transaksi-toko_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

   const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

     const fetchDataProduct = products => {
      apiClient.get('/api/filter/product', { params: { q: products } }).then(response => {
        dataProduct.value = response.data
      })
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
      fetchDataProduct()
    })

    const search = () => {
      fetchData()
    }



    return{
       q,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      region,
      provinsi,
      kabupaten,
      product,
      area,
      brand,
      onSelectChange,
      state,
      fetchXlsx,
      distributor,
      segment,
      fetchData,
        ...toRefs(state),
      start_date,
      end_date,
      search,
      dataProduct,
      dataBrand,
      uom,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    };
  },
});
</script>
